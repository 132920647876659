.mapControlsContainer {
    padding: 10px;
    position: absolute;
    z-index: 1000;
    bottom: 10px;
    right: 1px;
    width: 300px;
    height: 15px;
}

.latLonHeightDisplay {
    position: absolute;
    bottom: 1px;
    right: 10px;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: #FCCC0D;
}

.scaleContainer {
    position: absolute;
    bottom: 5px;
    right: 310px;
    font-size: 12px;
    font-family: Arial, sans-serif;
    color: #FCCC0D;
    text-align: center;
}

.scaleBar {
    position: relative;
    width: 100px; 
    height: 10px; 
    border: 2px solid #FCCC0D;
    border-top: none; 
    margin: 0 auto;
    background: transparent;
    display: inline-block;

}

.scaleLabel {
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 5px;
    font-size: 12px;
    border-radius: 3px;
    white-space: nowrap;

}
