.footerContainer {
	background-color: #17191A;
	color: #F9FCFF;
}

.footerContent {
	margin-left: 20px;
	margin-top: 13px;
}

.shapefileTitle {
	text-align: left;
	color: #727375;
	font-size: 14px;
	margin-bottom: 10px;
	margin-left:5px;
}

.shapefileName {
	color: #EAEBEB;
	text-align: left;
	font-size: 16px;
	margin-bottom: 10px;
	margin-left: 5px;
}

