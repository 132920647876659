
.listContainer {
    border-radius: 5px;
    width: 58px;
    border: 4px solid rgba(249, 252, 255, 0.4);
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.09), rgba(249, 252, 255, 0.09)), #292A2B;
}

.selectIcon {
    transform: rotate(-90deg);
}

.listItem {
    padding-top: 5px;
}

.listContainerSelected {
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease;
}

@keyframes color {
    from {
        border-color: #F9FCFF;
    }

    to {
        border-color: #FFB74D;
    }
}