.App {
    text-align: center;
    height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color:rgba(249, 252, 255, 0.11);

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #292A2B;
  border-radius: 10px;
  border: 1px solid rgba(249, 252, 255, 0.15);
}

::-webkit-scrollbar {
    background: #3E4041;
}


.react-resizable {
    position: relative;
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-position: bottom right;
    padding: 0 3px 3px 0;
}

.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
}

.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
}

.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
}

.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
}

.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
}

.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
}

.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
}

/*cesium viewer overrides*/

#cesiumContainer .cesium-viewer .cesium-viewer-toolbar {
    right: 65px;
    top: 15px;
}

