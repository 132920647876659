
.defectForm {
    /* background-color: red;; */
    /* display: flex;
    flex-direction: row; */
    padding-bottom: 18px;
    max-width: 100%;
}

.formTypesList {
    max-height: 210px;
    width: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

div[class*="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded"] {
    max-height: 300px !important;
    max-width: 400px !important;
}