.imageSearchContainer {
	width: 100%;
	height: 100%;
	font-family: Roboto;
}

.imageSearchContent {
	margin: 8px 8px 8px 8px;
}

.searchField {
	width: 380px;
	height: 100%;
	background-color: white;
}

.filterContainer {
	position: absolute;
	z-index: 100;
	min-width: max-content;
	
}

a:hover {
	cursor: pointer;
}