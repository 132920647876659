.container {
	background-color: #464A4F;
	font-family: Roboto;
	height: 100%;
	overflow: hidden;
	background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
	box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);
}

.selected {
	background: linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), linear-gradient(0deg, rgba(249, 252, 255, 0.11), rgba(249, 252, 255, 0.11)), #292A2B;
}

.groupDivider {
	background: black;
	opacity: 0.82;
	height: 8px;
	width: auto;
}

.listDivider {
	background: #292a2b;
	opacity: 0.92;
	height: 3px;
	width: auto;
}

.content {
	padding: 0px 0px 0px 0px;
	text-align: left;
	font-size: 14px;
	color: #D6D7D8;
	height: 102px;
	max-height: 102px;
}

.bigProp {
	font-size: 16px;
	color: #EAEBEB;
	padding-left: 3px;
	padding-top: 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 145px;
	height: 16px;
}

.thumbnail {
	height: 100%;
	width: 138px;
	padding: 4px;
}


.thumbnail img {
	vertical-align: middle;
	object-fit: cover;
	max-height: 89px;
}

.statusTab {
	width: 8px;
	box-shadow: 0px 8px 10px 1px rgba(30, 30, 32, 0.14), 0px 3px 14px 2px rgba(30, 30, 32, 0.12), 0px 5px 5px -3px rgba(30, 30, 32, 0.2);
}


.gridText {
	/* padding-top: 3px; */
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.iconInLine {
	padding-right: 3px;
	padding-left: 4px;
	/* position: relative;
	top: 3.2px; */
}

.gridRow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.sessionLabel {
	max-width: 145px;
	padding-left: 3px;
	padding-top: 5px;
	/* padding-bottom: 3px; */
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.linkAvatar {
	max-height: 25.6px;
	max-width: 25.6px;
	padding-left:40%;
	padding-bottom: 20%;
}
