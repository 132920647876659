.container {
	background-color: #3C3D3E;
	font-family: Roboto;
	border-radius: 5px;
	max-height: 800px;
}

.content {
	padding: 0px 10px 0px 10px;
	text-align: left;
	font-size: 14px;
	color: #D6D7D8;
}

.subHeadingText {
	color: #727375;
	font-size: 16px;
	margin-top: 10px;
}

.users {
	max-height: 210px;
	width: auto;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}