.toolIcon {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 60, 136, .5);
}

.toolButton {
    height: 35px;
    width: 45px;
    background-color: rgba(0, 60, 136, .5);
}

.outputText {
    margin-left: 10px;
    font-size: 14px;
    color: #FCCC0D;
    border: 1px solid #FCCC0D;
    background-color: rgba(0, 60, 136, .5)
}

.promptText {
    font-size: 14px;
    color: #FCCC0D;
    background-color: rgba(0, 60, 136, .5);
    padding: 0px 5px 5px 5px;
}

