.tagBtn {
	border-radius: 5px;
	background-color: #3C3D3E;
}

.tagContainer {
	position: absolute;
	bottom: -2px;
	left: 48px;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	padding: 2px;
	border-radius: 4px;
	background-color: #424344ec;
}