.popupContainer {
	display: flex;
	width: 100%;
	height: 100vh;
	flex-direction: column;
	background-color: #292A2B;
	font-family: Roboto;
}

.notConnectedMessage {
	margin: 20px;
	color: #F7FAFD;
}