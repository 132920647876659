.dziViewer {
	width: 100%;
	height: 100%;
	font-family: Roboto;
	position: relative;
}

.seadragonImageViewer {
	width: 100%;
	height: 100%;
}

.menuBar {
	background-color: rgba(30, 30, 32, .75);
	position: absolute;
	height: 50px;
	width: 100%;
	z-index: 99999;
}

.menuBarContent {
	padding: 5px 10px 5px 20px;
	text-align: left;
	font-size: 14px;
	color: #F9FCFF;
}

.filtersContainer {
	color: #F9FCFF;
	text-align:left;
	padding: 15px;
	position: absolute;
	right: 50px;
	top: 36px;
	width: 160px;
	height: 155px;
	z-index: 99999;
	background-color: #3C3D3E;
	border-radius: 5px;
}

.menuIcon {
	color: #F9FCFF;
	margin-right: 15px;
}

.statusContainer {
	background-color: #2F3031;
	border-radius: 25px;
	height: 30px;
	position: relative;
	margin-top: 4px;
}

.statusIcon {
	position: absolute;
	left: 0px;
	top: 6px;
}

.statusText {
	font-weight: 500;
	margin: 7px 6px 0px 25px;
}

.defectToolbar {
	position: absolute;
	top: 70px;
	left: 18px;
	z-index: 1200;
}

.defectToolbarContainer { 
	border-radius: 5px;
	background-color: #3C3D3E;
}

.defectList {
	position: absolute;
	top: 170px;
	right: 18px;
	z-index: 1200;
	border-radius: 15px;
	background-color: #424344;
	min-width: 212px;
	height: 240px;
}

.defectsContainer {
	color: #CBCED0;
	width: 212px;
	text-align: left;
	margin-left: 8px;
	max-height: 180px;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top:-10px
}

.defectListHeader {
	margin-bottom: 20px;
	margin-top: 20px;
	margin-left: 10px;
	text-align: left;
	color: #CBCED0;
	font-size: 14px;
}

.defectName {
	padding-top: 2px;
	color: #F9FCFF;
	font-size: 14px;
	text-align: left;
}

.defectIcon {
	height:16px;
	width:16px;
}

.defectColourDot {
	margin-bottom:1px;
	margin-right: 6px;
	height: 8px;
	width: 8px;
	border-radius: 50%;
	display: inline-block;
}

.formMenuItem {
	height: 48px;
	min-width: 200px;
}

.formStatusBox{
	margin-right:20px;
	margin-top:-10px
}

.formStatusImg{
	width:12px;
	height:13px; 
	position: absolute;
	margin-left: 9px;
	margin-top: 2px;
}

.formStatusFormIcon {
	font-size:20px;
	margin-top: -10px;
}