.carousel {
    padding: 50px 5px;
    overflow-y: auto;
    margin-bottom: 70px;
}

.arrowPrev {
    width: 20px;
    cursor: pointer;
}

.arrowNext {
    width: 20px;
    margin-left: -5px;
    cursor: pointer;
}

.thumbnailImage {
    width: 100%;
    padding: 5px;
}


.selected {
    background-color: #7F7F7F;
}

.datasetContainer {
    border: black;
    margin-bottom: 10px;
}

.datasetHeading {
    text-align: left;
    color: white;
    font-size: 14px;
    margin-bottom: 7px;
    font-weight: 500 !important;
}
.font10{
    font-size:10px !important;
}
.boldTxt {
    font-size: 14px;
    font-weight: bold;
    margin-left:10px;
}
