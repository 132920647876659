.classMenu {
    text-align: left;
    font-size: 14px;
    color: #F9FCFF;
}

.title {
    text-align: center;
    font-weight: bold;
    display: block;
}