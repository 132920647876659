.container {
    font-family: Roboto;
    text-align: center;
    background-color: #3c3d3e;
    border-radius: 5px;
}

.content {
    padding: 0px 10px 0px 10px;
    text-align: left;
    font-size: 14px;
    color: #d6d7d8;
}

.layout {
    width: 400px;
    height: 300px;
    background-color: #3C3D3E;
    padding: 20px;
    text-align: 'left';
    border-radius: 4px;
}