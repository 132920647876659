.content {
	text-align: left;
	height: 100%;
	font-family: Roboto;
}

.headerContainer {
	color: rgba(249, 252, 255, 0.75);
}

.headerContent {
	margin-left: 20px;
	font-size: 14px;
}

.footerContainer {
	background-color: #17191A;
	color: #F9FCFF;
}

.footerContent {
	margin-left: 20px;
	margin-top: 6px;
}

.headerText {
	margin-top: 14px;
}

.footerText{
	margin-top: 4px;
}

.imageSearchResultContainer {
	overflow: hidden;
	background: #292A2B;
}


.loader {
	margin-bottom: 20px;
	margin-right: 20px;
}


.noResults {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: rgba(249, 252, 255, 0.75);
}

.noResultsSub {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	
	letter-spacing: 0.25px;
	font-feature-settings: 'cpsp' on;
	color: rgba(249, 252, 255, 0.4);
	padding-top: 11px;
}