.container {
	border-radius:5px;
	background-color: #424344;
	margin-left: 8px;
	padding: 15px 15px 15px 15px;
}

.content {
	overflow-x: auto;
}

.linkPointer:hover {
	cursor: pointer;
}

.minimisedToolbar {
	padding: 15px;
	border-radius: 5px;
	position: fixed;
	bottom: 10px;
	right: 120px;
	width: 415px;
	height: 25px;
	background-color: #424344;
}