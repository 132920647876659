/* Imported from Sahils design in Figma   */


/* lf/typography/h4 */
.heading4 {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 140.62%;
	/* identical to box height, or 22px */
	/* colors/cloud */
	color: #F9FCFF;
}

/* lf/typography/bodyregularsmall */
.bodyRegularSmall {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140.62%;
	/* identical to box height, or 22px */
	/* colors/cloud */
	color: #F9FCFF;
	opacity: 0.75;
}


/* End imported section                 */

