.addContainer {
	position: absolute;
	bottom: -44px;
	left: 44px;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	padding: 0px;
	margin: 4px;
	width: 200px;
	border-radius: 4px;
	background-color: #424344ec;
}