/* Imported from Sahils design in Figma  */

/* primary-navbar */
.primaryNavbar {
	width: 100%;
	height: 50px;
	left: 0px;
	top: 0px;
	background: #32363A;
}

.virtualAssessment {
	position: absolute;
	width: 200px;
	height: 23px;
	left: 100px;
	top: 14px;
}

.virtualAssessmentIcon {
	position: absolute;
	left: 90px;
	top: 16px;
	padding-bottom: 3px;
} 

.menuIcons {
	position: absolute;
	right: 100px;
	top: 0px;
}

.userMenuItem {
	height: 48px;
	min-width: 200px;
}

.productMenuItem {
	height: 48px;
	min-width: 255px;
}

.signOutIcon {
	padding-right: 30px;
}


.empty {

}



/* End imported section                 */

.vaaContainer {
	display: flex;
	width: 100%;
	height: 100vh;
	flex-direction: column;
	
}

.tabsContainer {
	background-color: #2F3135;
	/* color: #95C5E7; */
	font-family: Roboto;
	position: absolute;
	left: 5px;
	top: 54px;
	opacity: 0.92;
	bottom:5px;
}

.mainContainer {
	width: 100%;
	height:100%;
	
}

.secondaryNavbarText {
	line-height: 50px;
	vertical-align: middle;
}

.imageSearchContainer{
	
	width:100%;
	background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
	
/* elevation/very low */

box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);

}

.assetSearchContainer{
	height:100%;
	
	width:100%;
	background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
/* elevation/very low */

box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);

}

.imageSearchResultsContainer {
	width: 100%;
	background: #292A2B;
	height: 100%;
	overflow: hidden;
}

.mapLayersContainer {
	width: 100%;
	height: 100%;
	background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
}


.moveCursor {
	cursor: move;
}

.noCursor {
	cursor: auto;
}

.dziViewerContainer {
	z-index: 1200;
	position: absolute;
	right: 90px;
	top: 80px;
	background-color: black;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.urlViewerContainer {
	z-index: 1200;
	position: absolute;
	right: 90px;
	top: 80px;
	background-color: black;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.threeDViewerContainer {
	z-index: 1200;
	position: absolute;
	right: 90px;
	top: 80px;
	background-color: black;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.mapBaseLayerSelectorContainer {
	z-index: 1200;
	position: absolute;
	right: 8px;
	top: 78px;
}

.mapPickerContainer {
	z-index: 10000;
	position: absolute;
	left: 520px;
	top: 140px;
}

.toolBarContainer {
	z-index: 1300; /*changed to 1300 from 1200, to display the confirmation message above dzi viewer*/
	position: absolute;
	right: 8px;
	top: 388px;
}

.generalSnackbarContainer {
	z-index: 1200;
	position: absolute;
	right: 8px;
	top: 388px;
}

.draggableWindowHeader {
	background-color: #404142;
	width: 100%;
	height: 48px;
}

.draggableWindowHeaderContent {
	padding: 15px 15px 0px 0px;
	background-color: #404142;
	display: flex;
	justify-content: flex-end;
}

.linkPointer:hover {
	cursor: pointer;
}

.imagesMessageText {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: rgba(249, 252, 255, 0.75);
}

.imageCarouselContainer {
	z-index: 999;
	position: absolute;
	left: 480px;
	bottom: 50px;
	height: 240px;
	max-width: calc(95% - 550px);
}

.threeDRotationIconBox {
	border: 3px solid rgba(249, 252, 255, 0.4);
	border-radius: 4px;
	width: 25px;
	height: 25px;
	margin-top: -24px;
}
.threeDRotationIconBox:hover {
	border: 3px solid rgba(255, 255, 255, 0.7);
}

.threeDRotationIcon {
	font-size: 20px !important;
	margin-top: -13px !important;
	margin-left: -9px !important;
}

.ThreeDViewerContainer {
	z-index: 1200;
	position: absolute;
	right: 8px;
	top: 210px;
}

