.container {
    font-family: Roboto;
    font-size: 14px;
    width: 229px;
    max-height: 400px;
    background-color: #424345ec;
    color: #D6D7D8;
    border-radius: 4px;
    overflow-x: hidden !important;
    height: 100%;
}

.content {
    padding: 10px 10px 10px 6px;
}

.header {
    margin-left: 6px;
    width: 100%;
    height: 35px;
    display: block;
}

.attributes {
    margin-left: 6px !important;
    font-size: 12px;
    height: auto !important;
}

.clear {
    font-size: 18px !important;
    margin-top: -14px !important;
}
