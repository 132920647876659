.container {
	background-color: #3C3D3E;
	font-family: Roboto;
	border-radius: 5px;
}

.content {
	padding: 0px 10px 0px 10px;
	text-align: left;
	font-size: 14px;
	color: #D6D7D8;
}

.users {
	max-height: 450px;
	width: auto;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}