.compassContainer {
    position: absolute;
    right: 7px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: white;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    fill: #edffff;
    box-sizing: content-box;
}

.compassDirection {
    position: absolute;
    font-size: 9px;
    font-weight: bold;
    cursor: pointer;
}

.compassDirection:nth-child(1) {
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.compassDirection:nth-child(2) {
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.compassDirection:nth-child(3) {
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.compassDirection:nth-child(4) {
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.compassDirection .arrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid red;
    position: absolute;
    bottom: 11px;
    left: 49%;
    transform: translateX(-50%);
    z-index: 1;
}

.compassHome {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(200, 200, 200, 0.3);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    color: #333;
    font-size: 12px;
}

.compassHome:hover {
    background-color: rgba(200, 200, 200, 0.5); 
}
