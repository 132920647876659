/*  Material UI Overrides */

.MuiTab-root.Mui-selected {
	color: #95C5E7;
}

.MuiTab-textColorInherit {
	opacity: 1;
}

.MuiButtonBase-root {
	color: #F9FCFF !important;
}

.MuiButtonBase-root:hover {
	color: #95C5E7 !important;
}

.MuiButtonBase-root:active {
	color: #95C5E7 !important;
}

.MuiPaper-root {
	background: linear-gradient(0deg, rgba(249, 252, 255, 0.09), rgba(249, 252, 255, 0.09)), #292A2B !important;
}

.MuiListItem-root:hover {
	background-color: rgba(147, 186, 245, 0.08) !important;
}

.MuiSvgIcon-root {
	min-width: 20px !important;
}
.MuiSvgIcon-colorAction {
	color: rgba(249,252,255,0.4) !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #F7B500 !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #F7B500 !important;	
}

.MuiCheckbox-root:hover {
	color: #F9FCFF !important;	
}

.MuiInputLabel-root {
	color: rgba(249, 252, 255, 0.4) !important;
	opacity: 0.6;
}

[class*="materialui-daterange-picker-makeStyles-filled-"] {
	background-color: #F7B500 !important;
}

[class*="materialui-daterange-picker-makeStyles-outlined-"] {
	border: 1px solid #F7B500 !important;
}

[class*="materialui-daterange-picker-makeStyles-icon-"] {
	color: rgba(249, 252, 255, 0.4) !important;
}

[class*="materialui-daterange-picker-makeStyles-highlighted-"] {
	background-color: rgba(247, 181, 0, 0.15) !important;
}

[class*="materialui-daterange-picker-makeStyles-contrast-"] {
	color: #1E1E20 !important;
}

[class*="materialui-daterange-picker-makeStyles-dateRangePickerContainer-"] {
	width: 700px;
}
[class*="materialui-daterange-picker-MuiSelect-selectMenu-"] {
	font-size: 0.9285714285714285rem !important;
}
[class*="materialui-daterange-picker-MuiSelect-icon-"] {
	color: rgba(249, 252, 255, 0.4) !important;
}

.MuiSvgIcon-colorDisabled {
	color: rgba(249, 252, 255, 0.4) !important;
}

.MuiIconButton-root.Mui-disabled {
	color: rgba(249, 252, 255, 0.4) !important;
}

.MuiTypography-colorTextSecondary {
	color: rgba(249, 252, 255, 0.4) !important;
}

.MuiTypography-subtitle1 {
	font-size:16px !important;
}
.MuiTypography-body2 {
	font-size: 16px !important;
}

.MuiLinearProgress-colorPrimary {
	background-color: #574b25 !important;
}

.MuiLinearProgress-bar {
	background-color:#F7B500 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
	background-color: #F7B500 !important;
	color: #F7B500 !important;
}
.MuiSwitch-colorPrimary.Mui-checked{
	color: #F7B500 !important;
}

.MuiSwitch-colorPrimary:hover {
	color: rgba(255, 255, 255) !important;
}

.MuiSwitch-switchBase {
	color:rgba(165, 165, 165) !important;
}

.MuiSwitch-colorPrimary.Mui-checked.Mui-disabled{
	color:rgba(126, 106, 42)!important;
}

.MuiSwitch-colorPrimary.Mui-disabled{
	color: rgba(81, 81, 81) !important;
}

.MuiSwitch-track {
	color:rgba(255, 255, 255)  !important;
	background-color:rgba(130, 131, 131)  !important;
}

.MuiPopover-root .MuiPaper-rounded {
	border-radius: 10px;
}

.MuiGridList-root {
	overflow-y: unset !important;
}

.MuiTypography-h5 {
	font-size: 20px !important;
	border-bottom: 0px;
}

.MuiTypography-subtitle2 {
	font-size: 16px !important;
	color: rgb(249,252,255,0.75);
}

.MuiButton-root.Mui-disabled {
	color:rgba(148,150,152) !important;
}

.MuiInputBase-root.Mui-disabled {
	color: rgba(249,252,255,0.4) !important;
}

.MuiGrid-grid-xs-6 {
	  overflow: hidden;
	  word-break: break-all;
	  text-overflow: ellipsis;
}

.MuiFormHelperText-root.Mui-error {
	color: #ff9999 !important;
}

.MuiFormLabel-asterisk.Mui-error {
	color: #ff9999 !important;
}