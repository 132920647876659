.threeDTileMenuIcon {
    position: absolute;
    z-index: 100;
}

.threeDTileMenuIcon> :hover {
    color: #466CA0;
    cursor: pointer;
}

.threeDTileMenuContainer {
    padding: 10px;
    background-color: #424344ec;
    position: absolute;
    z-index: 1000;
    left: 10px;
    overflow-y: hidden;
    max-height: 320px;
    border-radius: 15px;
}


.threeDTileMenuHeader {
    margin-top: 10px;
    color: #CBCED0;
    font-size: 14px;
    height: 25px;
}

.threeDTileMenuHeader>p {
    margin-top: 3px;
    font-weight: bold;
    font-size: 14px;
}


.toggleAllLink {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
}

.toggleAllLink:hover {
    text-decoration: underline;
}

.threeDTileMenuBody {
    max-height: 270px;
    overflow-y: auto;
    padding-bottom: 10px;
}


.threeDTileMenuBody ul {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
}

.threeDTileMenuBody li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.threeDTileMenuBody input[type="checkbox"] {
    margin-right: 10px;
}

.nothreeDTileMenuBody {
    width: 200px;
}


.layerColor {
    font-size: 17px;
    margin: 0 5px;
}

.layerName {
    padding-top: 2px;
    color: #F9FCFF;
    font-size: 14px;
    text-align: left;
    flex: 1;
}

.splitter {
    padding-top: 2px;
    color: #F9FCFF;
    font-size: 14px;
    text-align: left;
    flex: 1;
}