.menuContainer {
	position: relative;
	width: 280px;
	
	overflow: hidden;
	padding: 0px;
	margin: 0px;
}

.menuContents {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	
}

.confirmationMessageHeader {
	margin-bottom: 15px;
}

.confirmationMessage {
	position: absolute;
	margin-bottom: 10px;
	top: 0;
	left: 0;
	height: 100%;
	background-color: rgba(30, 30, 32, 0.9);
	padding: 15px;
	z-index:10;
	
}

.imageStatusDot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px
}

.imageStatusMenuHeader {
	font-family: Roboto;
	font-size: 16px;
	margin: 18px 45px 15px 15px;
	color: #F9FCFF;
}

.imageStatusMenuItem {
	font-family: Roboto;
	color: #F9FCFF;
}

